// Compiler result to JSON file
import { createComponent } from 'js/declViewModelService';
import { registerDynImportEntry } from 'js/moduleLoader';
import AwParseService from 'js/awParseService';
import AwFilterService from 'js/awFilterService';
import { getField } from 'js/utils';
import { Fragment } from 'react';
let filterSer = AwFilterService.instance;

/*
render function
*/
import { loginRenderFunction } from 'js/LoginService';

/*
ctx imports
*/

const ctxImportObj = {};

/*
dynamic import
 */
registerDynImportEntry('js/LoginService', () => import('js/LoginService'));
;

/*
view model
 */
const declViewModelJson = {
  "schemaVersion": "1.0.0",
  "imports": [],
  "actions": {
    "render": {
      "method": "loginRenderFunction",
      "deps": "js/LoginService"
    }
  },
  "dataProviders": {},
  "data": {},
  "conditions": {},
  "onEvent": [],
  "i18n": {},
  "lifecycleHooks": {
    "render": "render"
  },
  "_viewModelId": "Login",
  "_uniqueViewModelId": "Login",
  "ctx": {}
};

/*
define component
*/
const Component = createComponent(declViewModelJson, loginRenderFunction, undefined, ctxImportObj);
export default Component;